import React, { useState, useEffect } from 'react';
import { IoMdSend } from 'react-icons/io';
import { FaXmark } from 'react-icons/fa6';

const AnnotationForm = ({ annotation, onSave, onCancel, ref, i }) => {
  const [comment, setComment] = useState(annotation ? annotation.comment : '');

  useEffect(() => {
    setComment(annotation ? annotation.comment : '');
  }, [annotation]);

  const handleSave = () => {
    if (annotation) {
      onSave({ ...annotation, comment });
    } else {
      onSave({ id: Date.now(), x: 0, y: 0, comment });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div
      ref={ref}
      className="annotation-form"
      style={{
        position: 'absolute',
        top: annotation.y + 20,
        left: annotation.x + 120,
        zIndex: 1000,
      }}
    >
      <div className="textAreaBtnContainer">
        <textarea
          className="textarea"
          placeholder="Add a comment"
          value={comment}
          autoFocus
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
        <div className="commentBtnContainer">
          <button className="commentBtn" onClick={onCancel}>
            <FaXmark />
          </button>
          <button className="commentBtn" onClick={handleSave}>
            <IoMdSend />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnnotationForm;
