import { createSlice } from '@reduxjs/toolkit';

const rootCauseSVDSlice = createSlice({
    name: "rootCauseSVD",
    initialState: {
        info: {
            controlAccount: "",
            accountManager: ""
            }
    },
    reducers: {
        updateControlAccount(state, action) {
            state.info.controlAccount = action.payload;
        },
        updateAccountManager(state, action) {
            state.info.accountManager = action.payload;
        }
    }
});

export default rootCauseSVDSlice;