export const envVariables = {
  host: "https://dev-api.mavryck.com/service/PMIntelCore/rest/",
  getBasename: () => {
    const BASENAME = "/"; // DO NOT REMOVE OR MODIFY, very silly way of hanlding injecting basename into env variables... need better solution

    // If not replaced dynamically, fallback to "/"
    if (BASENAME === "__BASENAME__") {
      return "/";
    }

    return BASENAME;
  },
};
