import React, { useEffect, useState } from "react";
import PeopleList from "./PeopleList";
import ChatArea from "./ChatArea";

import "./style.css"

const Chat = (props) => {
    const [chatInfo, setChatInfo] = useState(null);

    const messageHandler = (chat) => {
        setChatInfo(chat);
    }

    useEffect(()=>{
        console.log("messages", chatInfo)
    }, [chatInfo])

    return (
        <div className="chat-backdrop">
            <div className="chatMainContent">
            <PeopleList messageHandler={messageHandler} />
            <ChatArea chatInfo={chatInfo} />
            </div>
        </div>
    )
};

export default Chat;
