import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commentingActions } from '../../store';
import Annotation from './Annotation';
import AnnotationForm from './AnnotationForm';
import './styles.css';

const AnnotationLayout = ({ children }) => {
  const dispatch = useDispatch();
  const annotationRef = useRef(null);
  const { visible, pageType, pageComment, allComments } = useSelector(
    (state) => state.commenting
  );
  const [currentUrl, setCurrentUrl] = useState('');
  const [annotations, setAnnotations] = useState([]);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [hoveredAnnotation, setHoveredAnnotation] = useState(null);

  const handleAddAnnotation = (e) => {
    if (isAdding || !visible || hoveredAnnotation) return;

    const x = e.clientX;
    const y = e.clientY;

    setSelectedAnnotation({
      id: Date.now(),
      x,
      y,
      comment: '',
      userName: 'Helbert',
      pageType: pageType,
      timeStamp: new Date().toISOString(),
      currentPage: currentUrl,
    });
    setIsAdding(true);
  };

  const handleSaveAnnotation = (annotation) => {
    // setAnnotations((prevAnnotations) => {
    //   const existingIndex = prevAnnotations.findIndex(
    //     (a) => a.id === annotation.id
    //   );
    //   if (existingIndex !== -1) {
    //     const updatedAnnotations = [...prevAnnotations];
    //     updatedAnnotations[existingIndex] = annotation;
    //     return updatedAnnotations;
    //   } else {
    //     return [...prevAnnotations, annotation];
    //   }
    // });
    dispatch(commentingActions.updatePageComment(annotation));
    setSelectedAnnotation(null);
    setIsAdding(false);
  };

  const handleEditAnnotation = (id) => {
    const annotation = pageComment.find((a) => a.id === id);
    setSelectedAnnotation(annotation);
    setIsAdding(true);
  };

  const handleCancel = () => {
    setSelectedAnnotation(null);
    setIsAdding(false);
  };

  const handleClickOutside = (event) => {
    if (
      annotationRef.current &&
      !annotationRef.current.contains(event.target)
    ) {
      setIsAdding(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsAdding(false);
    }
  };

  useEffect(() => {
    setIsAdding(false);
    const url = window?.location.href;
    const path = new URL(url).pathname;
    setCurrentUrl(path);
    dispatch(commentingActions.updatePageType(path));
  }, [visible]);

  // useEffect(() => {
  //   const url = window?.location.href;
  //   const path = new URL(url).pathname;
  //   setCurrentUrl(path);
  //   dispatch(commentingActions.updatePageType(path));
  //   //dispatch(commentingActions.updatePageComment(annotations));
  // }, [annotations]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // console.log('yy annotations', { allComments, pageComment });

  return (
    <div className="annotationPage" onClick={handleAddAnnotation}>
      {children}
      {visible &&
        pageComment?.map((annotation) => (
          <Annotation
            key={annotation.id}
            annotation={annotation}
            onEdit={handleEditAnnotation}
            onHover={setHoveredAnnotation}
            isHovered={
              hoveredAnnotation && hoveredAnnotation.id === annotation.id
            }
          />
        ))}
      {visible && isAdding && selectedAnnotation && (
        <AnnotationForm
          annotation={selectedAnnotation}
          onSave={handleSaveAnnotation}
          onCancel={handleCancel}
          ref={annotationRef}
        />
      )}
    </div>
  );
};

export default AnnotationLayout;
