import React, { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IoMdPaperPlane } from "react-icons/io";
import { chatActions } from "../../store";


const ChatArea = ({chatInfo}) => {
    const dispatch = useDispatch();
    const chatHistoryRef = useRef(null);
    const [msgText, setMsgText] = useState("")
    const [newChatNodes, setNewChatNodes] = useState([]);

    const sentMsgHandler = () => {
        if(msgText === "") return;
        const date = new Date();
        const hours = date.getHours();
        const mins = date.getMinutes();
        setNewChatNodes([...newChatNodes, {"sent": msgText,"time": `${hours}:${mins} ${hours >= 12 ? "PM" : "AM"}`}]);
        setMsgText("");
        setTimeout(() => {
            scrollToBottom();
        }, 100);
    }

    const scrollToBottom = () => {
        const chatHistory = chatHistoryRef.current;
        if (chatHistory) {
          chatHistory.scrollTop = chatHistory.scrollHeight;
        }
    };

    useEffect(()=>{
        scrollToBottom();
    }, [chatInfo])

    return (
        <div className="chat">
            {/* user info */}
            <div className="chat-header flex flex-wrap gap-x-4 justify-between items-center">
                    <div className="user-info inline-flex flex-wrap gap-x-2 items-center">
                    {
                        chatInfo ?
                        <Fragment>
                            <img src={`${process.env.PUBLIC_URL}/images/${chatInfo.image}`} alt="avatar"  />
                            <span className="name text-16 font-medium">{chatInfo.name}</span>
                        </Fragment>
                        : ""
                    }
                    </div>
                <button className="closeChat !bg-primary text-white py-2.5 px-8 rounded-3xl" onClick={()=>dispatch(chatActions.toggle())}>Close Chat</button>
            </div>
            {/* user info */}
            {/* chat history */}
            <div className="chat-history" ref={chatHistoryRef}>
                {
                    chatInfo ?
                    <ul className="m-b-0">
                        {
                            chatInfo.chat.map((node, index)=>(
                                <li className={`chat-node ${node.received ? "received" : "sent"}`} key={index}>
                                    <div className="message-data">
                                        <span className="message-data-time">{node.time}</span>
                                        {
                                            node.received ?
                                            <img alt="avatar" src={`${process.env.PUBLIC_URL}/images/${node.avatar}`} />
                                            : null
                                        }
                                    </div>
                                    <div className="message">{node.received ? node.received : node.sent}</div>
                                </li>
                            ))
                        }
                        {/* sent message */}
                        {
                            newChatNodes.map((node, index)=>(
                                <li className="chat-node sent" key={index}>
                                    <div className="message-data">
                                        <span className="message-data-time">{node.time}</span>
                                    </div>
                                    <div className="message">{node.sent}</div>
                                </li>
                            ))
                        }
                        {/* sent message */}
                    </ul>
                    : ""
                }
            </div>
            {/* chat history */}
            {/* typing area */}
            <div className="chat-message clearfix">
                {
                    chatInfo ?
                    <div className="input-group mb-0">
                        <div className="input-group-prepend">
                            <button className="sentMsg input-group-text" onClick={sentMsgHandler}>
                                <IoMdPaperPlane />
                            </button>
                        </div>
                        <input className="form-control" placeholder="Enter text here..." type="text" value={msgText} onChange={(e)=>setMsgText(e.target.value)} />
                    </div>
                    : ""
                }
            </div>
            {/* typing area */}
        </div>
    )
};

export default ChatArea;
