import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { AiOutlineSearch } from "react-icons/ai";


const PeopleList = ({messageHandler}) => {
    const selectedPerson = useSelector(state => state.chat.person);
    const [selectPersonIndex, setSelectPersonIndex] = useState(0)
    const [peopleList, setPeopleList] = useState([
        {
            name: "Rogene Lacanienta",
            image: "avatar2.jpg",
            status: "offline",
            time: "left 1 mins ago",
            chat: [
                {
                    "received": "The project is up and running.",
                    "time": "10:10 AM, Today",
                    "avatar": "avatar2.jpg"
                },
                {
                    "sent": "Which project?",
                    "time": "10:12 AM, Today"
                },
                {
                    "received": "The one we had an explanatory conversation about day before yesterday. Piling 1 installation",
                    "time": "10:13 AM, Today",
                    "avatar": "avatar2.jpg"
                },
                {
                    "sent": "Great.",
                    "time": "10:15 AM, Today"
                },
                {
                    "sent": "I will look into that and inform you if needed.",
                    "time": "10:15 AM, Today"
                }
            ]
        },
        {
            name: "Prashita Rao",
            image: "avatar3.jpg",
            status: "online",
            time: "",
            chat: [
                {
                    "received": "Hi Herberth, how are you? How is the project coming along?",
                    "time": "10:10 AM, Today",
                    "avatar": "avatar3.jpg"
                },
                {
                    "sent": "Are we meeting today?",
                    "time": "10:12 AM, Today"
                },
                {
                    "sent": "Project has been already finished and I have results to show you.",
                    "time": "10:13 AM, Today"
                },
                {
                    "received": "Ok. Excited to see the results.",
                    "time": "10:15 AM, Today",
                    "avatar": "avatar3.jpg"
                },
                {
                    "received": "I am available for the meeting after 8 pm today.",
                    "time": "12:15 PM, Today",
                    "avatar": "avatar3.jpg"
                },
                {
                    "sent": "Great. See you arround then.",
                    "time": "12:20 PM, Today"
                }
            ]
        },
        {
            name: "Anusha Saad",
            image: "avatar.jpg",
            status: "offline",
            time: "left 7 mins ago",
            chat: [
                {
                    "sent": "I have added ROV for the project 'Pile 4 cap installation'.",
                    "time": "11:39 AM, Today"
                },
                {
                    "received": "Ok, let me check.",
                    "time": "11:41 AM, Today",
                    "avatar": "avatar.jpg"
                },
                {
                    "received": "Did you assigned ROV to anyone?",
                    "time": "11:42 AM, Today",
                    "avatar": "avatar.jpg"
                },
                {
                    "sent": "No anusha, not yet.",
                    "time": "11:45 AM, Today"
                },
                {
                    "sent": "I just assigned one to you just now, and the rest still need to be assigned to their respective persons.",
                    "time": "11:46 AM, Today"
                },
                {
                    "received": "Ok, I got the notification.",
                    "time": "01:12 AM, Today",
                    "avatar": "avatar.jpg"
                }
            ]
        },
        {
            name: "Vincent Porter",
            image: "avatar4.jpg",
            status: "offline",
            time: "left 1 hour ago",
            chat : []
        }
    ]);

    const [filteredPeopleList, setFilteredPeopleList] = useState(peopleList);

    const [searchStr, setSearchStr] = useState("");

    const activeClassHandler = (e, index) => {
        const element = e.target;
        const list = element.parentElement;
        list.querySelector("li.active")?.classList.remove("active");
        element.classList.add("active");
        // console.log("index", index)
        messageHandler(peopleList[index]);
    }

    const searchPersonHandler = (e) => {
        const searchText = e.target.value;
        setSearchStr(searchText);
      
        const filteredList = peopleList.filter((person) =>
          person.name.toLowerCase().includes(searchText.toLowerCase())
        );
      
        setFilteredPeopleList(filteredList);
    };

    useEffect(()=> {
        console.log("inside people list: ", selectedPerson);
        if (selectedPerson !== null) {
            const selectedPersonObject = peopleList.find(person => person.name === selectedPerson);
            const index = peopleList.findIndex(person => person.name === selectedPerson);
            setSelectPersonIndex(index);
            messageHandler(selectedPersonObject);
        }
    }, [selectedPerson])

    return (
        <div className="people-list" id="plist">
            <div className="input-group">
                <div className="input-group-prepend"><span className="input-group-text"><AiOutlineSearch className="icon-magnifier" /></span></div>
                <input className="form-control" placeholder="Search..." type="text" value={searchStr} onChange={searchPersonHandler} />
            </div>
            <ul className="list-unstyled chat-list mt-2 mb-0">
                {
                    filteredPeopleList.map((person, index)=>(
                        <li className={`flex flex-wrap gap-x-3 items-center ${index === selectPersonIndex ? "active" : ""}`} key={index} onClick={(e)=>activeClassHandler(e, index)}>
                            <img src={`${process.env.PUBLIC_URL}/images/${person.image}`} alt="avatar"  />
                            <div className="about">
                                <div className="name">{person.name}</div>
                                <div className={`status flex flex-wrap gap-x-2 items-center ${person.status}`}><span></span> {person.status === "online" ? person.status : (person.time === "" ? "offline" : person.time)}</div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
};

export default PeopleList;
